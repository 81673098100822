import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'http/httpWrapper'

export const accountApi = createApi({
    reducerPath: 'accountApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        createAccount: builder.mutation({
            query: (credentials) => ({
                url: 'accounts',
                method: 'POST',
                body: credentials,
                credentials: "include"
            }),
        }),
        updateAccount: builder.mutation({
            query: ({id, ...rest}) => ({
                url: `accounts/${id}`,
                method: 'Patch',
                body: rest,
                credentials: "include"
            }),
        }),
        getAccount: builder.query({
            query: () => ({
                url: 'accounts',
                method: 'GET',
                credentials: "include"
            }),
        }),
        deleteAccount: builder.mutation({
            query: (id) => ({
                url: `accounts/${id}`,
                method: 'DELETE',
                credentials: "include"
            }),
        }),       
    }),
})

export const { useCreateAccountMutation, useGetAccountQuery, useDeleteAccountMutation, useUpdateAccountMutation } = accountApi



import React, { useState } from 'react';
import { AutoComplete, Card } from 'antd';
import { CloseSquareFilled } from '@ant-design/icons';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import { useGettaxOfficeRequisitesQuery } from '../services/TaxOfficeRequisites';

const StyledCard = styled(Card)`
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  max-width: 1000px;

  .ant-select-selector {
    border-radius: 8px !important;
    padding: 8px 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Details: React.FC = () => {
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const { data, isLoading, isError } = useGettaxOfficeRequisitesQuery(searchValue || '');

  const handleSelect = (value: any) => {
    setSearchValue(value);
  };

  const handleSearch = (value: string) => {
    if (data && data.results) {
      // const filteredOptions = data.results
      //   .filter((item: { office_name: string }) => item.name.toLowerCase().includes(value.toLowerCase()))
      //   .map((item: { name: string }) => ({ value: item.name }));

      setOptions(data.results);
    } else {
      setOptions([]);
    }
    setSearchValue(value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <StyledCard>
        <Title level={4}>Реквізити сплати податків</Title>
        <StyledAutoComplete
          value={searchValue}
          options={options}
          onSearch={handleSearch}
          onSelect={handleSelect}
          placeholder="Введіть назву покемона для пошуку"
          allowClear={{ clearIcon: <CloseSquareFilled /> }}
        />
      </StyledCard>
    </div>
  );
};

export default Details;

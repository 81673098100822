// authDB.ts
import { openDB } from 'idb';


const DB_NAME = 'appDatabase';
const STORE_NAME = 'auth';


async function getDB() {
  return await openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
}

export async function saveToken(token: string) {
  const db = await getDB();
  await db.put(STORE_NAME, token, 'access_token');
}


export async function getToken() {
  const db = await getDB();
  return db.get(STORE_NAME, 'access_token');
}


export async function deleteToken() {
  const db = await getDB();
  await db.delete(STORE_NAME, 'access_token');
}

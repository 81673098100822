import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'http/httpWrapper'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'auth/signin',
                method: 'POST',
                body: credentials,
                credentials: "include"
            }),
        }),
        checkAuth: builder.mutation({
            query: ({}) => ({
                url: 'auth/refresh',
                method: 'POST',
                credentials: 'include',
            }),
        }),

    }),
})

export const { useLoginMutation, useCheckAuthMutation } = authApi
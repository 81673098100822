import React, { useState, useEffect } from 'react';
import { Input, Button, Checkbox, Card, Typography } from 'antd';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

const { Title, Paragraph } = Typography;

const StyledCard = styled(Card)`
  width: 100%;
  margin-top: 20px;
`;

interface TaxData {
  esv: number;
  singleTax: number;
  totalTax: number;
  netIncome: number;
}

const TaxCalculator: React.FC = () => {
  const [income, setIncome] = useState<string>('');
  const [singleTaxRate, setSingleTaxRate] = useState<string>('5');
  const [includeEsv, setIncludeEsv] = useState<boolean>(true);
  const [taxData, setTaxData] = useState<TaxData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleCalculate = () => {
    const incomeNum = parseFloat(income);
    const singleTaxRateNum = parseFloat(singleTaxRate) / 100;

    if (isNaN(incomeNum) || isNaN(singleTaxRateNum)) {
      setError('Будь ласка, введіть дійсні числа.');
      return;
    }

    setError(null);

    const esvRate = 0.22;
    const minSalary = 6700; // Мінімальна зарплата в Україні
    const esv = includeEsv ? esvRate * minSalary : 0;
    const singleTax = singleTaxRateNum * incomeNum;
    const totalTax = esv + singleTax;
    const netIncome = incomeNum - totalTax;

    setTaxData({
      esv,
      singleTax,
      totalTax,
      netIncome,
    });
  };

  const handleClear = () => {
    setIncome('');
    setSingleTaxRate('5');
    setIncludeEsv(true);
    setTaxData(null);
    setError(null);
  };

  useEffect(() => {
    if (income && singleTaxRate) {
      handleCalculate();
    }
  }, [income, singleTaxRate, includeEsv]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleCalculate();
    }
  };

  const chartOptions: ApexCharts.ApexOptions = {
    labels: ['Чистий дохід', 'Єдиний податок', 'ЄСВ'],
    colors: ['#008FFB', '#FF4560', '#00E396'],
    chart: {
      width: '100%',
      type: 'pie',
    },
    title: {
      text: 'Розподіл доходу та податків',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#263238',
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      labels: {
        colors: '#263238',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['#ffffff'], // Світлий колір для відсотків
      },
      formatter: (val: number) => `${val.toFixed(2)}%`,
    },
  };

  const chartSeries = taxData ? [
    taxData.netIncome,
    taxData.singleTax,
    taxData.esv,
  ] : [0, 0, 0];

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <StyledCard>
        <Title level={4}>Калькулятор податків</Title>
        <div style={{ marginBottom: '16px' }}>
          <Input
            value={income}
            onChange={(e) => setIncome(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Введіть ваш дохід (₴)"
            style={{ marginBottom: '10px' }}
          />
          <Input
            value={singleTaxRate}
            onChange={(e) => setSingleTaxRate(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Введіть ставку єдиного податку (%)"
            style={{ marginBottom: '10px' }}
          />
          <Checkbox
            checked={includeEsv}
            onChange={() => setIncludeEsv(!includeEsv)}
            style={{ marginBottom: '10px' }}
          >
            Врахувати ЄСВ (22% з мінімальної зарплати)
          </Checkbox>
          {error && (
            <div style={{ color: 'red', marginBottom: '10px' }}>
              {error}
            </div>
          )}
          <Button
            type="primary"
            onClick={handleCalculate}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            Розрахувати
          </Button>
          <Button
            type="default"
            onClick={handleClear}
            style={{ width: '100%' }}
          >
            Очистити
          </Button>
        </div>
        {taxData && (
          <div style={{ marginTop: '16px' }}>
            <Title level={5}>Утримування</Title>
            <Paragraph>Дохід: ₴{parseFloat(income).toFixed(2)}</Paragraph>
            <Paragraph>ЄСВ (не обовʼязковий): ₴{taxData.esv.toFixed(2)}</Paragraph>
            <Paragraph>Єдиний податок: ₴{taxData.singleTax.toFixed(2)}</Paragraph>
            <Paragraph>Сума податків: ₴{taxData.totalTax.toFixed(2)}</Paragraph>
            <Paragraph>Чистий дохід за вирахуванням податків: ₴{taxData.netIncome.toFixed(2)}</Paragraph>
          </div>
        )}
      </StyledCard>
      <div style={{ width: '50%' }}>
        {income && taxData && taxData.netIncome >= 0 && (
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="pie"
            width="100%"
          />
        )}
      </div>
    </div>
  );
};

export default TaxCalculator;

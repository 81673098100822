import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'http/httpWrapper'

export const transactionApi = createApi({
    reducerPath: 'transactionApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        createTransaction: builder.mutation({
            query: (credentials) => ({
                url: 'Transactions',
                method: 'POST',
                body: credentials,
                credentials: "include"
            }),
        }),
        updateTransaction: builder.mutation({
            query: ({id, ...rest}) => ({
                url: `transactions/${id}`,
                method: 'Patch',
                body: rest,
                credentials: "include"
            }),
        }),
        getTransaction: builder.query({
            query: () => ({
                url: 'transactions',
                method: 'GET',
                credentials: "include"
            }),
        }),
        deleteTransaction: builder.mutation({
            query: (id) => ({
                url: `transactions/${id}`,
                method: 'DELETE',
                credentials: "include"
            }),
        }),       
    }),
})

export const { useCreateTransactionMutation, useGetTransactionQuery, useDeleteTransactionMutation, useUpdateTransactionMutation } = transactionApi



import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'http/httpWrapper'

export const taxOfficeRequisitesApi = createApi({
    reducerPath: 'taxOfficeRequisitesApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        gettaxOfficeRequisites: builder.query({
            query: () => ({
                url: 'tax-office-requisites',
                method: 'GET',
                credentials: "include"
            }),
        }),    
    }),
})

export const { useGettaxOfficeRequisitesQuery } = taxOfficeRequisitesApi



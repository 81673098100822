import React, { useState } from 'react';
import { Space, Table, Modal, Button, Form, Input, Select } from 'antd';
import type { TableProps } from 'antd';
import { useCreateAccountMutation, useDeleteAccountMutation, useGetAccountQuery, useUpdateAccountMutation } from 'services/AccountService';

interface DataType {
  id: string;
  name: string;
  bank: string;
  currency: string;
  iban: string;
  start_balance: number;
}

const currencyOptions = ['USD', 'EUR', 'UAH', 'GBP', 'JPY'];

const Account: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState<DataType | null>(null);
  const { data: dataAccount, refetch } = useGetAccountQuery('');
  const [createAccount] = useCreateAccountMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();

  const handleFinish = async (values: DataType) => {
    const formattedValues = { ...values, start_balance: Number(values.start_balance) };
    editingRecord
      ? await updateAccount({ ...formattedValues, id: editingRecord.id })
      : await createAccount(formattedValues);
    await refetch();
    setIsEditing(false);
    setEditingRecord(null);
  };

  const columns: TableProps<DataType>['columns'] = [
    { title: 'Назва', dataIndex: 'name', key: 'name' },
    { title: 'Банк', dataIndex: 'bank', key: 'bank' },
    { title: 'Валюта', dataIndex: 'currency', key: 'currency' },
    { title: 'IBAN', dataIndex: 'iban', key: 'iban' },
    { title: 'Початковий баланс', dataIndex: 'start_balance', key: 'start_balance' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => { setEditingRecord(record); setIsEditing(true); }}>Редагувати</a>
          <a onClick={async () => { await deleteAccount(record.id); await refetch(); }}>Видалити</a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" onClick={() => setIsEditing(true)}>
        Add Account
      </Button>
      <Table<DataType> columns={columns} dataSource={dataAccount} />
      <Modal
        title={editingRecord ? 'Редагувати' : 'Додати'}
        visible={isEditing}
        onCancel={() => { setIsEditing(false); setEditingRecord(null); }}
        footer={null}
      >
        <Form
          initialValues={editingRecord || { name: '', bank: '', currency: '', iban: '', start_balance: 0 }}
          onFinish={handleFinish}
        >
          {['name', 'bank'].map((field) => (
            <Form.Item name={field} label={field.charAt(0).toUpperCase() + field.slice(1)} key={field} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          ))}
          <Form.Item name="currency" label="Валюта" rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder="Select currency"
              options={currencyOptions.map((currency) => ({ value: currency, label: currency }))}
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="iban"
            label="IBAN"
            rules={[
              { required: true, message: 'Please enter IBAN' },
              { len: 29, message: 'IBAN має бути 29 символів' },
              { pattern: /^[A-Z0-9]+$/, message: 'IBAN має містити літери та цифри' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="start_balance" label="Початковий баланс" rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Account;

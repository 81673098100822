import axiosInstance from "./interceptor";
import { AxiosRequestConfig, AxiosResponse } from "axios";

interface AxiosBaseQueryArgs {
  url: string;
  method: AxiosRequestConfig["method"];
  body?: any;
  params?: any;
  headers?: Record<string, string>;
}

interface AxiosBaseQueryConfig {
  baseUrl?: string;
}

const axiosBaseQuery = 
  ({ baseUrl }: AxiosBaseQueryConfig = { baseUrl: "" }) =>
  async ({ url, method, params, headers, body }: AxiosBaseQueryArgs): Promise<AxiosResponse<any>> => {
    
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers,
        withCredentials: true
      });
      
      return Promise.resolve(result);
    } catch (axiosError: any) {

        return Promise.reject(axiosError?.response?.data);
    }
  };

export default axiosBaseQuery;

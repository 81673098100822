// src/App.tsx

import { Route, Routes, Navigate } from 'react-router-dom';
import Main from './layout/Main';
import Dashboard from './pages/Dashboard';
import SignIn from "./pages/SignIn";

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import ProtectedRoute from 'components/ProtectedRoute';
import SignUp from 'pages/SignUp';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import NotFound from 'pages/404';
import Details from 'pages/Details';
import Calendar from 'pages/Calendar';
import Calculator from 'pages/Сalculator';
import { ConfigProvider } from 'antd';
import Account from 'pages/Account';
import Transactions from 'pages/Transaction';

const AppRouter: React.FC = () => {
  const isAuth = useSelector((state: RootState) => state.isAuth.isAuth)

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#f4a725',
          borderRadius: 2,
        },
      }}>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />

        <Route path="/sign-in" element={isAuth ? <Navigate to="/dashboard" replace /> : <SignIn />} />
        <Route path="/sign-up" element={isAuth ? <Navigate to="/dashboard" replace /> : <SignUp />} />

        <Route element={<ProtectedRoute><Main /></ProtectedRoute>}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/details" element={<Details />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/account" element={<Account />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>


    </ConfigProvider>

  );
};

export default AppRouter;

// Sidenav.js
import React from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import logo from 'assets/images/logo-4.jpeg';
import { DashboardIcon, DetailsIcon, TransactionIcon, ProfileIcon, CalculatorIcon, CalendarIcon, AccountIcon } from './SidenavSvg';

function Sidenav({ color }:any) {
  const { pathname } = useLocation();
  const page = pathname.replace('/', '');

  const menuItems = [
    { key: '1', path: '/dashboard', label: 'Головна', icon: <DashboardIcon color={color} /> },
    { key: '2', path: '/details', label: 'Реквізити', icon: <DetailsIcon color={color} /> },
    { key: '3', path: '/calendar', label: 'Календар', icon: <CalendarIcon color={color} /> },
    { key: '4', path: '/calculator', label: 'Калькулятор', icon: <CalculatorIcon color={color} /> },
    { key: '5', path: '/transactions', label: 'Платежі', icon: <TransactionIcon color={color} /> },
    { key: '6', path: '/account', label: 'Рахунки', icon: <AccountIcon color={color} /> },
  ];

  return (
    <>
      <div className="brand">
        <img src={logo} alt="Logo" />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {menuItems.map((item) => (
          <Menu.Item key={item.key}>
            <NavLink to={item.path}>
              <span
                className="icon"
                style={{
                  background: page === item.path.slice(1) ? color : '',
                }}
              >
                {item.icon}
              </span>
              <span className="label">{item.label}</span>
            </NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
}

export default Sidenav;

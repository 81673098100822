import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { authApi } from 'services/AuthService'
import isAuthReducer from '../features/auth/isAuthSlice'
import { accountApi } from 'services/AccountService'
import { taxOfficeRequisitesApi } from 'services/TaxOfficeRequisites'
import { transactionApi } from 'services/TransactionService'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice

    isAuth: isAuthReducer,
    [authApi.reducerPath]: authApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [taxOfficeRequisitesApi.reducerPath]: taxOfficeRequisitesApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, accountApi.middleware, taxOfficeRequisitesApi.middleware, transactionApi.middleware ),
  
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

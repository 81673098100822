import { createSlice } from '@reduxjs/toolkit'

export interface IsAuthState {
  isAuth: boolean
}

const initialState: IsAuthState = {
  isAuth: false,
}

export const isAuthSlice = createSlice({
  name: 'isAuth',
  initialState,
  reducers: {
    setIsAuth: (state) => {
      state.isAuth = true
    },
    unsetIsAuth: (state) => {
      state.isAuth = false
    },
  },
})

export const { setIsAuth, unsetIsAuth } = isAuthSlice.actions

export default isAuthSlice.reducer
import React, { useState } from 'react';
import { Space, Table, Modal, Button, Form, Input, Select } from 'antd';
import { useGetTransactionQuery, useCreateTransactionMutation, useUpdateTransactionMutation, useDeleteTransactionMutation } from 'services/TransactionService';

const transactionTypes = ['income', 'expense'];
interface TransactionType {
    id: string;
    type: 'income' | 'expense'; 
    amount: number;
    accountId: number;
    description: string;
    transaction_date: string; 
  }
  
const Transactions: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState<TransactionType | null>(null);
  const { data: transactions, refetch } = useGetTransactionQuery('');
  const [createTransaction] = useCreateTransactionMutation();
  const [updateTransaction] = useUpdateTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();

  const handleFinish = async (values: TransactionType) => {
    const formattedValues = { ...values, amount: Number(values.amount), accountId: Number(values.accountId), transaction_date: new Date(values.transaction_date)  };
    editingRecord
      ? await updateTransaction({ ...formattedValues, id: editingRecord.id })
      : await createTransaction(formattedValues);
    await refetch();
    setIsEditing(false);
    setEditingRecord(null);
  };

  const columns = [
    { title: 'Опис', dataIndex: 'description', key: 'description' },
    { title: 'Тип', dataIndex: 'type', key: 'type' },
    { title: 'Сума', dataIndex: 'amount', key: 'amount' },
    { 
        title: 'Дата', 
        dataIndex: 'transaction_date', 
        key: 'transaction_date',
        render: (date: any) => new Date(date).toLocaleDateString('uk-UA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }), 
      },
    {
      title: 'Дія',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => { setEditingRecord(record); setIsEditing(true); }}>Редагувати</a>
          <a onClick={async () => { await deleteTransaction(record.id); await refetch(); }}>Видалити</a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" onClick={() => setIsEditing(true)}>Додати транзакцію</Button>
      <Table columns={columns} dataSource={transactions} />
      <Modal
        title={editingRecord ? 'Редагувати транзакцію' : 'Додати транзакцію'}
        visible={isEditing}
        onCancel={() => { setIsEditing(false); setEditingRecord(null); }}
        footer={null}
      >
        <Form
          initialValues={editingRecord || { type: 'income', amount: 0, description: '', date: '' }}
          onFinish={handleFinish}
        >
          <Form.Item name="description" label="Опис" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="type" label="Тип" rules={[{ required: true }]}>
            <Select
              options={transactionTypes.map(type => ({ value: type, label: type }))}
              placeholder="Обрати тип"
            />
          </Form.Item>
          <Form.Item name="accountId" label="Рахунок" rules={[{ required: true }]}>
          <Input type="number" />
          </Form.Item>
          <Form.Item name="amount" label="Сума" rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="transaction_date" label="Дата" rules={[{ required: true }]}>
            <Input type="date" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Зберегти</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Transactions;

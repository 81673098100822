import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import 'moment/locale/uk';
import { Select, Card } from 'antd';
import styled from 'styled-components';

moment.locale('uk');

const ukrainianMonths = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
];

const options: ApexOptions = {
  chart: {
    height: 350,
    type: 'rangeBar',
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.25,
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [50, 0, 100, 100],
    },
  },
  xaxis: {
    type: 'datetime',
    min: new Date('2024-01-01').getTime(),
    max: new Date('2024-12-31').getTime(),
    labels: {
      formatter: (value) => {
        const date = new Date(value);
        const month = date.getMonth();
        return ukrainianMonths[month];
      },
    },
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const seriesName = w.globals.seriesNames[seriesIndex];
      const value1 = series[seriesIndex][dataPointIndex];
      const date = new Date(value1);
      const month = date.getMonth() + 1;
      const quarter = Math.ceil(month / 3) - 1 === 0 ? 4 : Math.ceil(month / 3) - 1;
      const year = quarter === 4 ? date.getFullYear() - 1 : date.getFullYear();

      return `
      <div class="custom-tooltip">
        <div>${seriesName}: за ${quarter} квартал ${year} р.</div>
        <div>Здати до: ${date.toLocaleDateString('uk-UA')}</div>
      </div>
    `;
    },
  },
  legend: {
    position: 'top',
  },
  annotations: {
    xaxis: [
      {
        x: new Date().getTime(),
        borderColor: '#FF4560',
        label: {
          style: {
            color: '#fff',
            background: '#FF4560',
          },
          text: `Сьогодні`,
        },
      },
      {
        x: new Date('2024-01-01').getTime(),
        borderColor: '#00E396',
        label: {
          style: {
            color: '#fff',
            background: '#00E396',
          },
        },
      },
      {
        x: new Date('2024-04-01').getTime(),
        borderColor: '#FEB019',
        label: {
          style: {
            color: '#fff',
            background: '#FEB019',
          },
        },
      },
      {
        x: new Date('2024-07-01').getTime(),
        borderColor: '#FF4560',
        label: {
          style: {
            color: '#fff',
            background: '#FF4560',
          },
        },
      },
      {
        x: new Date('2024-10-01').getTime(),
        borderColor: '#775DD0',
        label: {
          style: {
            color: '#fff',
            background: '#775DD0',
          },
        },
      },
    ],
  },
};

const StyledCard = styled(Card)`
  border-radius: 16px;
  padding: 30px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
`;

const Calendar: React.FC = () => {
  const [state, setState] = useState<any>({
    series: [
      {
        name: 'Декларація платника податку',
        data: [
          {
            x: ' ',
            y: [new Date('2024-01-01').getTime(), new Date('2024-02-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-04-01').getTime(), new Date('2024-05-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-07-01').getTime(), new Date('2024-08-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-10-01').getTime(), new Date('2024-11-09').getTime()],
          },
        ],
      },
      {
        name: 'Єдиний податок',
        data: [
          {
            x: ' ',
            y: [new Date('2024-01-01').getTime(), new Date('2024-02-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-04-01').getTime(), new Date('2024-05-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-07-01').getTime(), new Date('2024-08-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-10-01').getTime(), new Date('2024-11-09').getTime()],
          },
        ],
      },
      {
        name: 'Єдиний соціальний внесок',
        data: [
          {
            x: ' ',
            y: [new Date('2024-01-01').getTime(), new Date('2024-02-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-04-01').getTime(), new Date('2024-05-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-07-01').getTime(), new Date('2024-08-09').getTime()],
          },
          {
            x: ' ',
            y: [new Date('2024-10-01').getTime(), new Date('2024-11-09').getTime()],
          },
        ],
      },
    ],
  });

  return (
    <StyledCard className="col-span-12 shadow-default dark:bg-boxdark xl:col-span-4">
      <div className="mb-4">
        <StyledSelect defaultValue="2024">
          <Select.Option value="2024">2024</Select.Option>
          <Select.Option value="2025">2025</Select.Option>
        </StyledSelect>
      </div>

      <div id="Calendar" className="-ml-5 -mb-9">
        <ReactApexChart options={options} series={state.series} type="rangeBar" height={350} />
      </div>
    </StyledCard>
  );
};

export default Calendar;
